import React, {Fragment} from 'react';
import {getPathFromCommand} from './prompt';
import {useNavigate, useSearchParams} from '@remix-run/react';

export const Command = ({children}: {children: string}) => {
  const path = getPathFromCommand({command: children});
  const navigate = useNavigate();
  const [, setURLSearchParams] = useSearchParams();

  const handleNavigate = () => {
    if (children.includes(' ')) {
      const [inputCommand] = children.split(' ');
      setURLSearchParams({command: inputCommand});
    } else {
      navigate(path);
    }
  };

  return (
    <code className="text-primary">
      &gt;{' '}
      <button data-link={true} onClick={handleNavigate}>
        {children}
      </button>
    </code>
  );
};

export const Commands = ({
  commands = {},
}: {
  commands: {[key: string]: React.ReactNode};
}) => {
  return (
    <div className="not-prose grid grid-cols-[auto_1fr] gap-x-8">
      {Object.entries(commands).map(([key, value], i) => (
        <Fragment key={i}>
          <Command>{key}</Command>
          <div>{value}</div>
        </Fragment>
      ))}
    </div>
  );
};

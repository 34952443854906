import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from './ui/collapsible';
import {useLocalStorage} from 'usehooks-ts';
import React from 'react';

type HelpProps = {
  children: React.ReactNode;
};

export const Help = ({children}: HelpProps) => {
  const [open, setOpen] = useLocalStorage('help-panel-open', true, {
    initializeWithValue: true,
  });
  return (
    <Collapsible
      defaultOpen={open}
      className="group relative"
      open={open}
      onOpenChange={setOpen}
    >
      <CollapsibleTrigger className="right-0 font-title font-bold text-foreground/80 hover:bg-foreground hover:text-background data-[state=open]:hidden">
        ? help
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="relative mb-2 border border-dashed border-foreground/80 p-2 pt-0 text-foreground/80">
          <span className="-top-3.5 left-0 inline-block bg-background font-title font-bold">
            ? help
          </span>
          <CollapsibleTrigger className="absolute -right-px -top-px hidden px-2 font-title hover:bg-foreground hover:text-background group-hover:inline-block">
            x hide
          </CollapsibleTrigger>
          <div className="prose text-sm">{children}</div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
